:root {
    --primary-color: #FF4F3E;
    --secondary-color: #ffb53e;
    --chat-border-radius: 20px;
    --user-bubble-bg: var(--primary-color);
    --user-bubble-color: #fff;
    --bot-bubble-bg: #fff;
    --bot-bubble-color: #fff;
    --agent-bubble-bg: #D6FAFF;
    --agent-bubble-color: #000;
    --chat-b-radius: 12px;
    --chat-toggle-radius: 50%;
    --header-bg-color: var(--primary-color);
    --header-color: #fff;


    //floating window
    --fcard-bg: var(--primary-color);
    --fcard-text: #fff;
    --fcard-inner-bg: #fff;
    --fcard-inner-text: #000;
    --float-height: "100px";

    --primary-fonts: 'Mulish';
    --btn-bg: #FF4F3E;
    --btn-color: #000;
    --btn-outline-bg: #FF4F3E;
    --btn-outline-color: #fff;
    --btn-hover-bg: #FF4F3E;
    --btn-hover-color: #000;

    --mobile-height: 100vh;
}

* {
    box-sizing: border-box !important;
    letter-spacing: 0.5px;
}

@font-face {
    font-family: 'Mulish';
    src: url('../../fonts/mulish/Mulish-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Mulish';
    src: url('../../fonts/mulish/Mulish-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Mulish';
    src: url('../../fonts/mulish/Mulish-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Mulish';
    src: url('../../fonts/mulish/Mulish-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: block;
}

// josephins
@font-face {
    font-family: "Josefin";
    src: url('../../fonts/josefin/JosefinSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "Josefin";
    src: url('../../fonts/josefin/JosefinSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "Josefin";
    src: url('../../fonts/josefin/JosefinSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "Josefin";
    src: url('../../fonts/josefin/JosefinSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "Josefin";
    src: url('../../fonts/josefin/JosefinSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: block;
}

//roboto
@font-face {
    font-family: 'Roboto', sans-serif;
    src: url('../../fonts/roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Roboto', sans-serif;
    src: url('../../fonts/roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Roboto', sans-serif;
    src: url('../../fonts/roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Roboto', sans-serif;
    src: url('../../fonts/roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Roboto', sans-serif;
    src: url('../../fonts/roboto/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: block;
}

//source sans
@font-face {
    font-family: 'Source Sans Pro', sans-serif;
    src: url('../../fonts/source_sans_pro/SourceSansPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Source Sans Pro', sans-serif;
    src: url('../../fonts/source_sans_pro/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Source Sans Pro', sans-serif;
    src: url('../../fonts/source_sans_pro/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Source Sans Pro', sans-serif;
    src: url('../../fonts/source_sans_pro/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Source Sans Pro', sans-serif;
    src: url('../../fonts/source_sans_pro/SourceSansPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: block;
}
body{
    background-color: #000;
}

.MuiMenu-root {
    z-index: 999999 !important;

    .MuiMenu-list {
        max-height: 200px !important;
    }
}

.talkk-chat-wrapper-c {
    .input-hidden {
        opacity: 0;
        height: 0;
        width: 0;
        position: absolute;
        left: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: bold !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    div,
    a,
    li,
    ul,
    label {
        line-height: normal !important;
        font-family: "Montserrat", sans-serif !important;
    }

    .css-t3ipsp-control,
    .PhoneInput--focus {
        box-shadow: none !important;
        border-color: var(--primary-color) !important;
    }

    .PhoneInput {
        min-height: 38px;
    }

    .form-group,
    .message-form-group {
        label {
            font-size: 14px !important;
            color: #333 !important;

            &.MuiInputLabel-formControl {
                font-size: 14px !important;
            }

            &.MuiFormLabel-filled,
            &.MuiInputLabel-shrink {
                font-size: 18px !important;
            }
        }

        .form-control {
            min-height: 38px !important;
            border-radius: 4px !important;

            &:focus {
                border-color: var(--primary-color) !important;
                box-shadow: none !important;
            }
        }
    }

    font-family: "Montserrat", sans-serif !important;
    box-sizing: border-box !important;

    ::-webkit-scrollbar {
        width: 0 !important;
        height: 5px !important;
    }

    ::-webkit-scrollbar-track {
        background: rgb(255,255,255,0.1) !important;
    }

    ::-webkit-scrollbar-thumb {
        background: rgb(183, 185, 189, 0.5) !important;
        border-radius: 10px !important;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: rgb(133, 131, 131) !important;
    }

    .audio-player-wrapper {
        >div:first-child {
            flex-direction: row !important;
        }

        svg,
        span {
            font-size: 10px;
            margin: 0 7px !important;
        }

        img {
            width: 30px !important;
            height: 30px !important;
            // display: none;
        }

        #waveform {
            max-width: 135px !important;
        }
    }

    .ml-auto {
        margin-left: auto !important;
    }

    button {
        cursor: pointer !important;
    }

    a {
        text-decoration: none !important;
    }

    .mb-8 {
        margin-bottom: 8px !important;
    }

    .intl-tel-input {
        display: block !important;
    }

    .form-control {
        font-family: "Montserrat", sans-serif !important;
        font-size: 14px !important;
        display: block !important;
        // width: -webkit-fill-available !important;
        width: 100% !important;
        height: auto !important;
        min-height: 38px;
        padding: 0.375rem 0.75rem !important;
        font-weight: 400 !important;
        line-height: 1.5 !important;
        color: #495057 !important;
        background-color: transparent !important;
        background-clip: padding-box !important;
        border: 1px solid #ced4da !important;
        border-radius: 0.25rem !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;

        &::-ms-expand {
            background-color: transparent !important;
            border: 0 !important;
        }

        &:focus {
            color: #fff !important;
            background-color: transparent !important;
            // border-color: #80bdff !important;
            outline: 0 !important;
            // box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
        }

        &::-webkit-input-placeholder {
            color: #fff !important;
            opacity: 1 !important;
        }

        &::-moz-placeholder {
            color: #fff !important;
            opacity: 1 !important;
        }

        &:-ms-input-placeholder {
            color: #fff !important;
            opacity: 1 !important;
        }

        &::-ms-input-placeholder {
            color: #fff !important;
            opacity: 1 !important;
        }

        &::placeholder {
            color: #fff !important;
            opacity: 1 !important;
        }

        &:disabled,
        &[readonly] {
            background-color: #e9ecef !important;
            opacity: 1 !important;
        }
    }

    .bot-typing {
        width: 58px !important;
        display: flex !important;
        justify-content: center !important;
        height: 43px !important;
        align-items: center !important;
        box-shadow: none !important;
        background-color: #ffffff !important;
        text-align: left !important;
        color: #000000 !important;
        overflow-wrap: break-word !important;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10) !important;
        border-radius: 7px 7px 7px 0px !important;
        border: 1px solid #E3E3E3 !important;
        margin: 0 7px 19px !important;

        .dot {
            animation: mercuryTypingAnimation 1.8s infinite ease-in-out !important;
            background-color: orange !important;
            border-radius: 50% !important;
            height: 7px !important;
            margin-right: 4px !important;
            vertical-align: middle !important;
            width: 7px !important;
            display: inline-block !important;
        }

        .dot:nth-child(1) {
            animation-delay: 200ms !important;
        }

        .dot:nth-child(2) {
            animation-delay: 300ms !important;
        }

        .dot:nth-child(3) {
            animation-delay: 400ms !important;
        }

        .dot:last-child {
            margin-right: 0 !important;
        }
    }

    @keyframes mercuryTypingAnimation {
        0% {
            transform: translateY(0px) !important;
            background-color: #ff7b00 !important;
        }

        28% {
            transform: translateY(-7px);
            background-color: #fdb674 !important;
        }

        44% {
            transform: translateY(0px);
            background-color: #fddec0 !important;
        }
    }
}