.talkk-web-chatbot-main-wrap {
    .placeholder {
        display: inline-block;
        min-height: 1em;
        vertical-align: middle;
        cursor: wait;
        background-color: currentColor;
        opacity: 0.1;

        &.btn::before {
            display: inline-block;
            content: "";
        }
    }

    // Sizing
    .placeholder-xs {
        min-height: .6em;
    }

    .placeholder-sm {
        min-height: .8em;
    }

    .placeholder-lg {
        min-height: 1.2em;
    }

    // Animation
    .placeholder-glow {
        .placeholder {
            animation: placeholder-glow 2s ease-in-out infinite;
        }
    }

    @keyframes placeholder-glow {
        50% {
            opacity: 0.2;
        }
    }

    .placeholder-wave {
        mask-image: linear-gradient(130deg, #242424 55%, rgba(0, 0, 0, (1 - 0.2)) 75%, #242424 95%);
        mask-size: 200% 100%;
        animation: placeholder-wave 2s linear infinite;
    }

    @keyframes placeholder-wave {
        100% {
            mask-position: -200% 0%;
        }
    }

    .iframe-skeleton {
        position: absolute;
        top: 79px;
        width: 100%;
        height: calc(100% - 101px);
        left: 0;
        background-color: #e5e5e5;
        z-index: 99;

        .iframe-body {
            padding: 20px;
            height: 100%;
            position: relative;
            padding-bottom: 60px;
        }

        .w-90 {
            width: 90%;
            display: block;
            margin-bottom: 10px;
        }

        .w-70 {
            width: 70%;
            display: block;
            margin-bottom: 10px;
        }

        .w-60 {
            width: 60%;
            display: block;
            margin-bottom: 10px;
        }

        .w-40 {
            width: 40%;
            display: block;
            margin-bottom: 10px;
        }

        .w-50 {
            width: 50%;
            display: block;
            margin-bottom: 10px;
        }

        .w-20 {
            width: 20%;
            display: block;
            margin-bottom: 10px;
        }

        .img-load-block {
            width: 100%;
            height: calc(100% - 240px);
            margin-bottom: 10px;
            display: block;
        }

        .iframe-header {
            width: 100%;
            height: 40px;
        }

        .btn-loader-sk {
            width: 88%;
            border-radius: 4px;
            height: 40px;
            position: absolute;
            left: 20px;
            bottom: 20px;
        }
    }
}